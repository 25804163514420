/* eslint-disable eol-last */
import axios from 'axios'
import qs from 'qs'
import { API_BASE_URL } from 'config/api'
import { configureAxiosDefaults, get, post, postImage, put, remove, removeAxiosTokenHeader } from './utils'

export function configureToken(token) {
  configureAxiosDefaults('Authorization', token ? `Bearer ${token}` : null)
}

export const getClientInfo = domain => {
  const url = `/api/client/?domain=${domain}`
  return get(url)
}

export const refreshToken = refresh => {
  const url = `${API_BASE_URL}/auth/jwt/refresh/`
  return axios.post(url, { refresh })
}

export function removeAxiosToken() {
  removeAxiosTokenHeader()
}

export const getUserToken = data => {
  const url = '/auth/jwt/create/'
  return post(url, data)
}

export const getMe = () => {
  const url = '/auth/users/me/'
  return get(url)
}

export const setTheme = data => {
  console.log('Set theme ', data)
  const url = '/api/themes/'
  return postImage(url, data)
}

export const getTheme = () => {
  const url = '/api/themes/'
  return get(url)
}

export const clientSignUp = body => {
  const url = '/api/client-signup/'
  return post(url, body)
}

// DASHBOARD

export const getDashboard = (measure, params) => {
  const { club, period, date } = params
  const noDateParams = {
    club,
    period,
  }
  const dateParams = {
    club,
    period,
    start: date?.start,
    end: date?.end,
  }
  let url = ''
  if (params.period === 'custom' && params.date !== '') {
    url = `/api/dashboard/${measure}?${qs.stringify(dateParams, { skipNulls: true })}`
  } else {
    url = `/api/dashboard/${measure}?${qs.stringify(noDateParams, { skipNulls: true })}`
  }
  return get(url)
}

export const getStadistics = query => {
  const { club, year, month, monthLimit } = query
  let clubQuery = ''
  if (club !== undefined || club !== '') clubQuery = `&club=${club}`
  const url = `/api/statistics?year=${year}&month=${month}&month_limit=${monthLimit}${clubQuery}`
  return get(url)
}

export const getInvoiceReconcile = query => {
  const { filter } = query
  console.log(filter)
  let statusQuery = ''
  let typeQuery = ''
  let startQuery = ''
  let endQuery = ''
  let teamQuery = ''
  let orderQuery = ''
  let officeQuery = ''
  if (filter?.status !== undefined && filter.status !== '') statusQuery = `&status=${filter.status}`
  if (filter?.type !== undefined && filter.type !== '') typeQuery = `&invoice_type=${filter.type}`
  if (filter?.start !== undefined && filter.start !== '') startQuery = `&start=${filter.start}`
  if (filter?.end !== undefined && filter.end !== '') endQuery = `&end=${filter.end}`
  if (filter?.team !== undefined && filter.team !== '') teamQuery = `&team=${filter.team}`
  if (filter?.office !== undefined && filter.office !== '') officeQuery = `&office=${filter.office}`
  if (filter?.order !== undefined && filter.order !== '') orderQuery = `&order=${filter.order}`
  const url = `/api/dashboard-reconcile-info/?page_size=10000${statusQuery}${typeQuery}${teamQuery}${orderQuery}${startQuery}${endQuery}${officeQuery}`
  return get(url)
}

// TEAMS

export const getTeams = params => {
  const url = `/api/teams/?${qs.stringify(params, { skipNulls: true })}`
  return get(url)
}

export const getClientsReferral = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  if (filter?.name !== undefined && filter?.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  const typeQuery = '&role=broker'
  const url = `/api/clients/?page_size=${pageSize}&page_number=${currentPage}${nameQuery}${orderQuery}${typeQuery}`
  return get(url)
}

export const newGetTeams = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  if (filter?.name !== undefined && filter?.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  const url = `/api/teams/?page_size=${pageSize}&page_number=${currentPage}${nameQuery}${orderQuery}`
  return get(url)
}

export const getTeamsWithInvoiceData = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  if (filter?.name !== undefined && filter?.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  const url = `/api/teams/?club=${filter.office}&populate=datas&page_size=${pageSize}&page_number=${currentPage}${nameQuery}${orderQuery}`
  return get(url)
}

export const getTeamProfile = props => {
  const url = `/api/dashboard-team/?team=${props}`
  return get(url)
}

export const setTeam = data => {
  const url = '/api/teams/'
  return post(url, data)
}

export const updateTeam = (slug, data) => {
  const url = `/api/teams/${slug}`
  return put(url, data)
}

//  BOOKINGS
export const getBookings = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  let typeQuery = ''
  let startQuery = ''
  let endQuery = ''
  let waQuery = ''
  let statusQuery = ''
  let paymentQuery = ''
  let originQuery = ''
  let clientQuery = ''
  let teamQuery = ''
  let memberQuery = ''
  let guestQuery = ''
  let officeQuery = ''
  let resQuery = ''
  let busyQuery = ''

  if (filter?.start !== undefined && filter?.start !== '' && filter?.start !== null) startQuery = `&start=${filter?.start}`
  if (filter?.end !== undefined && filter?.end !== '' && filter?.end !== null) endQuery = `&end=${filter?.end}`
  if (filter.client !== undefined && filter?.client !== '' && filter?.client !== null) clientQuery = `&client=${filter?.client}`
  if (filter.team !== undefined && filter?.team !== '' && filter?.team !== null) teamQuery = `&team=${filter?.team}`
  if (filter.resource !== undefined && filter?.resource !== '') resQuery = `&resource=${filter?.resource}`
  if (filter.office !== undefined && filter?.office !== '') officeQuery = `&club=${filter?.office}`
  if (filter.guest !== undefined && filter?.guest !== '') guestQuery = `&guest=${filter?.guest}`
  if (filter.member !== undefined && filter?.member !== '') memberQuery = `&member=${filter?.member}`
  if (filter.origin !== undefined && filter?.origin !== '') originQuery = `&origin=${filter?.origin}`
  if (filter.status !== undefined && filter?.status !== '') statusQuery = `&status=${filter?.status}`
  if (filter.payment !== undefined && filter?.payment !== '') paymentQuery = `&payment=${filter?.payment}`
  if (filter.work_area !== undefined && filter?.work_area !== '') waQuery = `&work_area=${filter?.work_area}`
  if (filter?.name !== undefined && filter?.name !== '') nameQuery = `&name=${filter?.name}`
  if (filter?.order !== undefined && filter?.order !== '' && filter?.order !== 'none') orderQuery = `&order=${filter?.order}`
  if (filter?.booking_type !== undefined && filter?.booking_type !== '') typeQuery = `&booking_type=${filter?.booking_type}`
  if (filter?.busy !== undefined && filter?.busy !== '') busyQuery = `&force_busy=${filter?.busy}`
  const url = `/api/dashboard-member-bookings/?&page_size=${pageSize}&page_number=${currentPage}${endQuery}${startQuery}${resQuery}${memberQuery}${guestQuery}${officeQuery}${originQuery}${clientQuery}${teamQuery}${paymentQuery}${statusQuery}${waQuery}${nameQuery}${orderQuery}${typeQuery}${busyQuery}`
  return get(url)
}

export const setBooking = booking => {
  const url = '/api/member-bookings/'
  return post(url, booking)
}

export const getSingleBooking = slug => {
  const url = `api/member-bookings/${slug}`
  return get(url)
}

export const calculateBookingPrice = params => {
  const { team, client, office, bookingType, workArea, date, fullArea } = params
  let { bookingSize } = params
  let teamQuery = ''
  let clientQuery = ''
  let waQuery = ''
  let dateQuery = ''
  let fullQuery = ''

  if (team !== undefined && team !== '' && team !== null) teamQuery = `&team=${team}`
  if (client !== undefined && client !== '' && client !== null) clientQuery = `&client=${client}`
  if (bookingType === 'meeting_room') bookingSize = 1
  if (workArea !== undefined && workArea !== '' && workArea !== null) waQuery = `&work_area=${workArea}`
  if (date !== undefined && date !== '' && date !== null) dateQuery = `&date=${date}`
  if (fullArea) fullQuery = '&full_area=true'
  const url = `/api/calculate-booking-desk-price/?office=${office}&booking_type=${bookingType}&booking_size=${bookingSize}${teamQuery}${clientQuery}${waQuery}${dateQuery}${fullQuery}`
  return get(url)
}

export const getDashboardBookings = () => {
  const url = '/api/dashboard-member-bookings/'
  return get(url)
}

export const bookingSpaceAvailable = params => {
  const { waSlug, dateStart, dateEnd } = params
  const url = `api/space-availability/?work_area=${waSlug}&start=${dateStart}&end=${dateEnd}`
  return get(url)
}

export const updateBooking = (bCode, data) => {
  const url = `/api/member-bookings/${bCode}`
  return put(url, data)
}

export const getMeetingRoomAvailability = data => {
  let codeQuery = ''
  let teamQuery = ''
  let clientQuery = ''
  if (data?.code !== undefined && data?.code !== '' && data?.code !== null) codeQuery = `&code=${data?.code}`
  if (data?.team !== null && data?.team !== '' && data?.team !== null) teamQuery = `&team=${data?.team}`
  if (data?.client !== null && data?.client !== '' && data?.client !== null) clientQuery = `&client=${data?.client}`
  const url = `/api/meeting-room-availability/?resource=${data.resource}&start=${data.start}&end=${data.end}${codeQuery}${teamQuery}${clientQuery}`
  return get(url)
}

export const getAvailableBookings = () => {
  const url = '/api/team-bookings-available/'
  return get(url)
}

//  MEMBERS

export const getMembers = () => {
  const url = '/api/member-list/'
  return get(url)
}

export const setMember = data => {
  const url = '/api/member-list/'
  return post(url, data)
}

export const updateMember = data => {
  const url = '/api/profile/'
  return put(url, data)
}

export const getMember = props => {
  const url = `/api/dashboard-profile/?member=${props}`
  return get(url)
}

export const newGetMembers = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let teamQuery = ''
  let orderQuery = ''
  let presenceQuery = ''
  let officeQuery = ''
  let roleQuery = ''
  if (filter.team !== undefined && filter.team !== '') teamQuery = `&team=${filter.team}`
  if (filter.name !== undefined && filter.name !== '') nameQuery = `&name=${filter.name}`
  if (filter.order !== undefined && filter.order !== '') orderQuery = `&order=${filter.order}`
  if (filter.office !== undefined && filter.office !== '') officeQuery = `&club=${filter.office}`
  if (filter.presence !== undefined && filter.presence !== '') presenceQuery = `&presence=${filter.presence}`
  if (filter.role !== undefined && filter.role !== '') roleQuery = `&role=${filter.role}`
  const url = `/api/member-list/?page_size=${pageSize}&page_number=${currentPage}${nameQuery}${orderQuery}${teamQuery}${officeQuery}${presenceQuery}${roleQuery}`
  return get(url)
}

export const getMembersByTeam = slug => {
  //const url = `/api/team-members/?team=${slug}&referral=true`
  const url = `/api/team-members/?team=${slug}`
  return get(url)
}

export const checkMemberMail = params => {
  const url = `api/email-is-valid/${params}`
  return get(url)
}

// INVOICES

export const getDashboardInvoices = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let statusQuery = ''
  let typeQuery = ''
  let sentQuery = ''
  let startQuery = ''
  let endQuery = ''
  let teamQuery = ''
  let orderQuery = ''
  let officeQuery = ''
  if (filter?.name !== undefined && filter?.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.status !== undefined && filter.status !== '') statusQuery = `&status=${filter.status}`
  if (filter?.type !== undefined && filter.type !== '') typeQuery = `&invoice_type=${filter.type}`
  if (filter?.sent !== undefined && filter.sent !== '') sentQuery = `&sent=${filter.sent}`
  if (filter?.start !== undefined && filter.start !== '') startQuery = `&start=${filter.start}`
  if (filter?.end !== undefined && filter.end !== '') endQuery = `&end=${filter.end}`
  if (filter?.team !== undefined && filter.team !== '') teamQuery = `&team=${filter.team}`
  if (filter?.office !== undefined && filter.office !== '') officeQuery = `&office=${filter.office}`
  if (filter?.order !== undefined && filter.order !== '') orderQuery = `&order=${filter.order}`
  const url = `/api/dashboard-invoices/?club=${filter.layoutOffice}&page_size=${pageSize}&page_number=${currentPage}${nameQuery}${statusQuery}${typeQuery}${teamQuery}${orderQuery}${startQuery}${endQuery}${officeQuery}${sentQuery}`
  return get(url)
}

export const getProfileInvoice = props => {
  const url = `/api/invoices/${props}`
  return get(url)
}

export const getInvoiceSync = props => {
  const url = `/api/invoice-sync-status/${props}`
  return get(url)
}

export const setInvoiceSyncPayments = props => {
  const url = `/api/invoice-sync-payment/${props}`
  return post(url)
}

export const setInvoiceDeletePayments = props => {
  const url = `/api/invoice-delete-payments/${props}`
  return post(url)
}

export const sendInvoice = props => {
  const url = `/api/invoice-send/${props}`
  return post(url)
}

export const createInvoiceFromExternal = data => {
  const url = '/api/invoice-sync-external/'
  return post(url, data)
}

export const getAvailableInvoices = props => {
  const url = `/api/invoices-available/${props}`
  return get(url)
}

export const getInvoiceByTeam = props => {
  const url = `api/invoice-datas/${props}`
  return get(url)
}

export const getSyncInvoice = data => {
  const url = '/api/invoice-sync/'
  return post(url, data)
}

export const getValidateInvoice = data => {
  const url = '/api/invoice-validate/'
  return post(url, data)
}

export const getReviewInvoice = data => {
  const url = '/api/invoice-review/'
  return post(url, data)
}

export const setInvoiceData = data => {
  const url = '/api/invoice-datas/'
  return post(url, data)
}

export const updateInvoiceData = (data, slug) => {
  const url = `/api/invoice-data/${slug}`
  return put(url, data)
}

export const updatePayout = (slug, data) => {
  const url = `/api/payouts/${slug}`
  return put(url, data)
}

// MEMBERSHIPS

export const getMemberships = () => {
  const url = '/api/membership-plans/'
  return get(url)
}

export const getDashboardMemberships = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  let statusQuery = ''
  let teamQuery = ''
  let memberQuery = ''
  let memberShipQuery = ''
  let paidQuery = ''
  let officeQuery = ''
  if (filter?.name !== undefined && filter?.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.status !== undefined && filter?.status !== '') statusQuery = `&status=${filter.status}`
  if (filter?.team !== undefined && filter?.team !== '') teamQuery = `&team=${filter.team}`
  if (filter?.member !== undefined && filter?.member !== '') memberQuery = `&member=${filter.member}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  if (filter?.membership !== undefined && filter?.membership !== '') memberShipQuery = `&plan_membership=${filter.membership}`
  if (filter?.paid !== undefined && filter?.paid !== '') paidQuery = `&paid=${filter.paid}`
  if (filter?.office !== undefined && filter?.office !== '') officeQuery = `&club=${filter.office}`
  const url = `/api/dashboard-memberships/?page_size=${pageSize}&page_number=${currentPage}${officeQuery}${nameQuery}${statusQuery}${teamQuery}${memberQuery}${memberShipQuery}${paidQuery}${orderQuery}`
  return get(url)
}

export const getMembershipSingle = slug => {
  const url = `/api/memberships/${slug}`
  return get(url)
}

export const getMembershipsByTeam = props => {
  const { team } = props
  const queryMember = ''
  let queryTeam = ''
  if (team !== undefined) queryTeam = `team=${team}&`
  //if (member !== undefined) queryMember = `member=${member}&`
  const url = `/api/memberships-team/?${queryMember}${queryTeam}status=active&pre_paid=true`
  return get(url)
}

export const setMembership = data => {
  const url = '/api/memberships/'
  return post(url, data)
}

export const updateMembership = (data, slug) => {
  const url = `/api/memberships/${slug}`
  return put(url, data)
}

// RESOURCES

export const getSpaces = query => {
  const { start, end, office, bookingType, team, floor, client } = query
  const queryStart = start === undefined || start === '' ? '' : `start=${start}`
  const queryEnd = end === undefined || end === '' ? '' : `&end=${end}`
  let queryOffice = office === undefined || office === '' ? '' : `&club=${office}`
  const queryType = bookingType === undefined || bookingType === '' ? '' : `&booking_type=${bookingType}`
  const queryTeam = team === undefined || team === '' ? '' : `&team=${team}`
  const queryClient = client === undefined || client === '' ? '' : `&client=${client}`
  const queryFloor = floor === undefined || floor === '' ? '' : `&floor=${floor}`
  if (queryFloor !== '') queryOffice = ''
  const url = `api/space-availability/?${queryStart}${queryEnd}${queryOffice}${queryType}${queryTeam}${queryFloor}${queryClient}`
  return get(url)
}

export const getDashboardResources = query => {
  const { pageSize, currentPage, filter } = query
  console.log(query)
  let nameQuery = ''
  let orderQuery = ''
  let typeQuery = ''
  let statusQuery = ''
  let officeQuery = ''
  let workAreaQuery = ''
  if (filter?.name !== undefined && filter?.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  if (filter?.resource_type !== undefined && filter?.resource_type !== '') typeQuery = `&resource_type=${filter.resource_type}`
  if (filter?.status !== undefined && filter?.status !== '') statusQuery = `&status=${filter.status}`
  if (filter?.office !== undefined && filter?.office !== '') officeQuery = `&office=${filter.office}`
  if (filter?.work_area !== undefined && filter?.work_area !== '') workAreaQuery = `&status=${filter.work_area.slug}`
  const url = `/api/dashboard-resources/?page_size=${pageSize}&page_number=${currentPage}${nameQuery}${typeQuery}${statusQuery}${officeQuery}${workAreaQuery}${orderQuery}`
  return get(url)
}

export const getFloorResources = query => {
  const { filter } = query
  const url = `/api/dashboard-floor-resources/?floor=${filter.floor}&status=active`
  return get(url)
}

export const getResourceSingle = slug => {
  const url = `/api/resources/${slug}`
  return get(url)
}

export const setResource = data => {
  const url = '/api/resources/'
  return post(url, data)
}

export const updateResource = (data, slug) => {
  const url = `/api/resources/${slug}`
  return put(url, data)
}

export const getResourcesForWorkArea = props => {
  const url = `/api/resources/?work_area=${props}`
  return get(url)
}

export const getResourcesForOffice = props => {
  const url = `/api/resources/?office=${props}`
  return get(url)
}

export const getResourcesMRForOffice = props => {
  const url = `/api/resources/?resource_type=meeting_room&office=${props}`
  return get(url)
}

export const getResourcesMRForAll = () => {
  const url = '/api/resources/?resource_type=meeting_room'
  return get(url)
}

export const getResourceMR = props => {
  const url = `/api/resources/?resource_type=meeting_room&resource=${props}`
  return get(url)
}

// WORK AREAS

export const getWorkAreas = () => {
  const url = 'api/work-areas/all'
  return get(url)
}

export const getWorkAreasForOffice = slug => {
  const url = `/api/work-areas/${slug}`
  return get(url)
}

export const getWAbyFloor = floorSlug => {
  const url = `/api/dashboard-work-areas/?floor=${floorSlug}`
  return get(url)
}

export const createWorkArea = data => {
  const url = '/api/work-areas/'
  return post(url, data)
}

export const createCL = data => {
  const url = '/api/dashboard-check-lists/'
  return post(url, data)
}

export const getDashboardWorkAreas = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  let typeQuery = ''
  let statusQuery = ''
  let officeQuery = ''
  let occupancyQuery = ''
  let availabilityQuery = ''
  if (filter?.status !== undefined && filter?.status !== '') statusQuery = `&status=${filter.status}`
  if (filter?.area_type !== undefined && filter?.area_type !== '') typeQuery = `&area_type=${filter.area_type}`
  if (filter?.name !== undefined && filter?.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  if (filter?.office !== undefined && filter?.office !== '') officeQuery = `&office=${filter.office}`
  if (filter?.show_occupancy !== undefined && filter?.show_occupancy !== '') occupancyQuery = '&show_occupancy=hot_desk'
  if (filter?.availability_from !== undefined && filter?.availability_from !== '') availabilityQuery = `&availability_from=${filter.availability_from}`
  const url = `/api/dashboard-work-areas/?page_size=${pageSize}&page_number=${currentPage}${statusQuery}${nameQuery}${orderQuery}${typeQuery}${officeQuery}${occupancyQuery}${availabilityQuery}`
  return get(url)
}

export const getDashboardCheckLists = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  let statusQuery = ''
  let officeQuery = ''
  if (filter?.status !== undefined && filter?.status !== '') statusQuery = `&status=${filter.status}`
  if (filter?.name !== undefined && filter?.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  if (filter?.office !== undefined && filter?.office !== '') officeQuery = `&office=${filter.office}`
  const url = `/api/dashboard-check-lists/?page_size=${pageSize}&page_number=${currentPage}${statusQuery}${nameQuery}${orderQuery}${officeQuery}`
  return get(url)
}

export const getDashboardCheckListExecutions = (query, slug) => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  let statusQuery = ''
  if (filter?.status !== undefined && filter?.status !== '') statusQuery = `&status=${filter.status}`
  if (filter?.name !== undefined && filter?.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  const url = `/api/dashboard-check-list-executions/${slug}?page_size=${pageSize}&page_number=${currentPage}${statusQuery}${nameQuery}${orderQuery}`
  return get(url)
}

export const getDashboardTariffs = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  let typeQuery = ''
  let statusQuery = ''
  let officeQuery = ''
  let occupancyQuery = ''
  let availabilityQuery = ''
  if (filter?.status !== undefined && filter?.status !== '') statusQuery = `&status=${filter.status}`
  if (filter?.area_type !== undefined && filter?.area_type !== '') typeQuery = `&area_type=${filter.area_type}`
  if (filter?.name !== undefined && filter?.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  if (filter?.office !== undefined && filter?.office !== '') officeQuery = `&club=${filter.office}`
  if (filter?.show_occupancy !== undefined && filter?.show_occupancy !== '') occupancyQuery = '&show_occupancy=hot_desk'
  if (filter?.availability_from !== undefined && filter?.availability_from !== '') availabilityQuery = `&availability_from=${filter.availability_from}`
  const url = `/api/dashboard-tariffs/?page_size=${pageSize}&page_number=${currentPage}${statusQuery}${nameQuery}${orderQuery}${typeQuery}${officeQuery}${occupancyQuery}${availabilityQuery}`
  return get(url)
}

export const getSingleWA = slug => {
  const url = `/api/dashboard-work-areas/${slug}`
  return get(url)
}

export const updateWA = (slug, data) => {
  const url = `/api/dashboard-work-areas/${slug}`
  return put(url, data)
}

export const getSingleCL = slug => {
  const url = `/api/dashboard-check-lists/${slug}`
  return get(url)
}

export const updateCL = (slug, data) => {
  const url = `/api/dashboard-check-lists/${slug}`
  return put(url, data)
}

export const setNewChecklistItem = data => {
  const url = '/api/check-list-items/'
  return post(url, data)
}

export const updateChecklistItem = (data, slug) => {
  const url = `/api/check-list-items/${slug}`
  return put(url, data)
}

export const deleteChecklistItem = slug => {
  const url = `/api/check-list-items/${slug}`
  return remove(url)
}

//OFFICES

export const getDashboardOffices = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  if (filter?.name !== undefined && filter?.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  const url = `/api/dashboard-offices/?page_size=${pageSize}&page_number=${currentPage}${nameQuery}${orderQuery}`
  return get(url)
}

export const createOffice = data => {
  const url = '/api/dashboard-offices/'
  return post(url, data)
}

export const updateOffice = (slug, data) => {
  const url = `/api/dashboard-offices/${slug}`
  return put(url, data)
}

export const getSingleOffice = slug => {
  const url = `api/dashboard-offices/?club=${slug}`
  return get(url)
}

export const getOffices = () => {
  const url = '/api/staff-profile/'
  return get(url)
}

export const updateActiveOffice = slug => {
  const url = '/api/staff-profile/'
  return put(url, { active_office: slug })
}

// GUESTS

export const getGuest = () => {
  const url = '/api/guests/'
  return get(url)
}

export const getDashboardGuests = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  if (filter?.name !== undefined && filter?.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  const url = `/api/guests/?page_size=${pageSize}&page_number=${currentPage}${nameQuery}${orderQuery}`
  return get(url)
}

export const getGuestByTeam = slug => {
  const url = `/api/team-guests/?team=${slug}`
  return get(url)
}

// EVENTS

export const getDashboardEvents = query => {
  const { pageSize, currentPage, filter } = query
  console.log(filter)
  let nameQuery = ''
  let statusQuery = ''
  let startQuery = ''
  let endQuery = ''
  let clientQuery = ''
  let orderQuery = ''
  let officeQuery = ''
  if (filter?.name !== undefined && filter?.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.status !== undefined && filter.status !== '') statusQuery = `&status=${filter.status}`
  if (filter?.start !== undefined && filter.start !== '' && filter.start !== null) startQuery = `&start=${filter.start}`
  if (filter?.end !== undefined && filter.end !== '' && filter.end !== null) endQuery = `&end=${filter.end}`
  if (filter?.client !== undefined && filter.client !== '') clientQuery = `&client=${filter.client}`
  if (filter?.office !== undefined && filter.office !== '') officeQuery = `&office=${filter.office}`
  if (filter?.order !== undefined && filter.order !== '') orderQuery = `&order=${filter.order}`
  const url = `/api/dashboard-events/?page_size=${pageSize}&page_number=${currentPage}${nameQuery}${statusQuery}${clientQuery}${orderQuery}${startQuery}${endQuery}${officeQuery}`
  return get(url)
}
// MARKETING

export const getCampaigns = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  if (filter?.name !== undefined && filter?.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  const url = `/api/dashboard-notification-campaigns/?page_size=${pageSize}&page_number=${currentPage}${nameQuery}${orderQuery}`
  return get(url)
}

export const getOffers = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  if (filter.name !== undefined && filter.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  const url = `/api/dashboard-offers/?page_size=${pageSize}&page_number=${currentPage}${nameQuery}${orderQuery}`
  return get(url)
}

export const setOffer = offer => {
  const url = '/api/dashboard-offers/'
  return post(url, offer)
}

export const updateOffer = offer => {
  const url = `/api/offers/${offer.slug}`
  return put(url, offer)
}

export const getOffersUsers = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  if (filter.name !== undefined && filter.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  const url = `/api/dashboard-offer-users/?&page_size=${pageSize}&page_number=${currentPage}${nameQuery}${orderQuery}`
  return get(url)
}

export const setOfferUser = offerUser => {
  const url = '/api/offer-users/'
  return post(url, offerUser)
}

export const updateOfferUser = offerUser => {
  const url = `/api/offer-users/${offerUser.slug}`
  return put(url, offerUser)
}

export const getSingleCampaign = slug => {
  const url = `/api/notification-campaigns/${slug}`
  return get(url)
}

export const updateCampaign = data => {
  const { campaignSlug, userList, name, code, datePlanned, status } = data
  const info = {
    name,
    user_list: userList,
    code,
    date_planned: datePlanned,
    status,
  }
  const url = `/api/notification-campaigns/${campaignSlug}`
  return put(url, info)
}

export const getTypesCampaign = () => {
  const url = '/api/notification-types/'
  return get(url)
}

export const createCampaign = data => {
  const url = '/api/notification-campaigns/'
  return post(url, data)
}

export const resetPassword = email => {
  const url = '/api/forgot/'
  return post(url, { email })
}

export const sendInvitation = slug => {
  const url = '/api/invite/'
  return post(url, { member_slug: slug })
}

export const setNewInvoiceLine = data => {
  const url = '/api/invoice-lines/'
  return post(url, data)
}

export const updateInvoiceLine = (data, slug) => {
  const url = `/api/invoice-lines/${slug}`
  return put(url, data)
}

export const deleteInvoiceLine = (slug, action) => {
  const url = `/api/invoice-lines/${slug}`
  return remove(url, { action })
}

export const setInvoice = data => {
  const url = '/api/invoices/'
  return post(url, data)
}

export const putInvoice = (data, slug) => {
  const url = `/api/invoices/${slug}`
  return put(url, data)
}

export const removeInvoice = slug => {
  const url = `/api/invoices/${slug}`
  return remove(url)
}

export const getGuests = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  if (filter?.name !== undefined && filter?.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  const url = `/api/guests/?page_size=${pageSize}&page_number=${currentPage}${nameQuery}${orderQuery}`
  return get(url)
}

export const getGuestSingle = slug => {
  const url = `api/guests/${slug}`
  return get(url)
}

export const setNewGuest = data => {
  const url = '/api/guests/'
  return post(url, data)
}

export const updateGuest = (data, slug) => {
  const url = `/api/guests/${slug}`
  return put(url, data)
}

export const removeGuest = slug => {
  const url = `/api/guests/${slug}`
  return remove(url)
}

export const getOrders = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  let statusQuery = ''
  let officeQuery = ''
  let startQuery = ''
  let endQuery = ''
  if (filter.status !== undefined && filter.status !== '') statusQuery = `&status=${filter.status}`
  if (filter.name !== undefined && filter.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  if (filter?.office !== undefined && filter?.office !== '') officeQuery = `&club=${filter.office}`
  if (filter?.start !== undefined && filter.start !== '') startQuery = `&start=${filter.start}`
  if (filter?.end !== undefined && filter.end !== '') endQuery = `&end=${filter.end}`
  const url = `/api/dashboard-orders/?page_size=${pageSize}&page_number=${currentPage}${nameQuery}${orderQuery}${statusQuery}${officeQuery}${startQuery}${endQuery}`
  return get(url)
}

export const getSingleOrder = slug => {
  const url = `/api/orders/${slug}`
  return get(url)
}

export const updateOrder = (slug, data) => {
  const url = `/api/orders/${slug}`
  return put(url, data)
}

export const getStockList = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  let statusQuery = ''
  if (filter.status !== undefined && filter.status !== '') statusQuery = `&status=${filter.status}`
  if (filter.name !== undefined && filter.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  const url = `/api/dashboard-products/?page_size=${pageSize}&page_number=${currentPage}${nameQuery}${orderQuery}${statusQuery}`
  return get(url)
}

export const createProduct = data => {
  const url = '/api/products/'
  return post(url, data)
}

export const getProductCategories = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  let statusQuery = ''
  if (filter.status !== undefined && filter.status !== '') statusQuery = `&status=${filter.status}`
  if (filter.name !== undefined && filter.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  const url = `/api/product-categories/?page_size=${pageSize}&page_number=${currentPage}${nameQuery}${orderQuery}${statusQuery}`
  return get(url)
}

export const createProductCategory = data => {
  const url = '/api/product-categories/'
  return post(url, data)
}

export const getBarsList = query => {
  const { pageSize, currentPage, filter } = query
  let nameQuery = ''
  let orderQuery = ''
  let statusQuery = ''
  if (filter.status !== undefined && filter.status !== '') statusQuery = `&status=${filter.status}`
  if (filter.name !== undefined && filter.name !== '') nameQuery = `&name=${filter.name}`
  if (filter?.order !== undefined && filter?.order !== '') orderQuery = `&order=${filter.order}`
  const url = `/api/bars/?page_size=${pageSize}&page_number=${currentPage}${nameQuery}${orderQuery}${statusQuery}`
  return get(url)
}

// ACCOUNT

export const getDashboardCompany = () => {
  const url = '/api/dashboard-company'
  return get(url)
}

export const getDashboardMSPlans = () => {
  const url = '/api/dashboard-membership-plans/'
  return get(url)
}

export const createDashboardMSPlans = data => {
  const url = '/api/dashboard-membership-plans/'
  return post(url, data)
}

export const updateDashboardMSPlans = (slug, data) => {
  const url = `/api/dashboard-membership-plans/${slug}`
  return put(url, data)
}

export const getDashboardStaff = () => {
  const url = '/api/dashboard-staff/'
  return get(url)
}

export const setDashboardStaff = data => {
  const url = '/api/dashboard-staff/'
  return post(url, data)
}

export const getStaffSingle = slug => {
  const url = `/api/dashboard-staff/${slug}`
  return get(url)
}

export const updateStaffMember = (slug, data) => {
  const url = `/api/dashboard-staff/${slug}`
  return put(url, data)
}

export const getCompanyDetails = slug => {
  const url = `/api/dashboard-company/${slug}`
  return get(url)
}

export const updateProfile = data => {
  const url = '/api/staff-profile'
  return put(url, data)
}

//ONBOARDING

export const updateClient = data => {
  const url = '/api/client/'
  return put(url, data)
}

export const getPlansBoarding = () => {
  const url = '/api/client-plan-type/?type=private'
  return get(url)
}

export const getDoors = () => {
  const url = '/api/doors/'
  return get(url)
}

export const remoteOpenDoor = data => {
  const url = '/api/doors/'
  return post(url, data)
}
