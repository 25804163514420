import React, { Component } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { initTranslation } from 'assets/i18n'
import { interceptorToken } from 'api/utils'
import Context from 'context'
import 'App.css'
import Providers from './Providers'

const queryClient = new QueryClient()

class App extends Component {
  constructor(props) {
    super(props)

    this.setOffice = office => {
      this.setState({
        office,
      })
    }

    this.setSections = sections => {
      this.setState({
        sections,
      })
    }

    this.setActiveClient = activeClient => {
      console.log(activeClient)
      this.setState({
        activeClient,
      })
    }

    this.setRole = role => {
      console.log(role)
      this.setState({
        role,
      })
    }

    this.setProfile = profile => {
      console.log(profile)
      this.setState({
        profile,
      })
    }

    this.state = {
      office: null,
      setOffice: this.setOffice,
      sections: [],
      setSections: this.setSections,
      activeClient: null,
      setActiveClient: this.setActiveClient,
      role: null,
      setRole: this.setRole,
      profile: null,
      setProfile: this.setProfile,
    }
    interceptorToken()
    initTranslation()
  }

  render() {
    console.log(this.state)
    const {
      office,
      setOffice,
      sections,
      setSections,
      activeClient,
      setActiveClient,
      role,
      setRole,
      profile,
      setProfile,
    } = this.state
    return (
      <Context.Provider
        value={{
          ...this.state,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Providers
            office={office}
            sections={sections}
            activeClient={activeClient}
            setOffice={setOffice}
            setSections={setSections}
            setActiveClient={setActiveClient}
            role={role}
            setRole={setRole}
            profile={profile}
            setProfile={setProfile}
          />
        </QueryClientProvider>
      </Context.Provider>
    )
  }
}

export default App
