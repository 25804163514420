import { useEffect, useState } from 'react'
import {
  getDashboardWorkAreas,
  getMembersByTeam,
  newGetTeams,
  calculateBookingPrice,
  setBooking,
  getClientsReferral,
} from 'api'
import { useTranslation } from 'react-i18next'
import { Grid, Button, FormControl, TextField, Checkbox, Modal } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { TimePicker, DatePicker } from '@material-ui/pickers'
import { PropTypes } from 'prop-types'
import { colors } from 'config/colors'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import formatDates from '../../atoms/formatDates/formatDates'
import FeedbackModal from '../../molecules/modals/feedbackModal'
import { ModalCard, ModalTitle } from './modalStyles'

function ModalCreateDesk({ closeModal, theDay, deskData, office, role, activeClient, activeTeam }) {
  console.log(activeClient)
  console.log(role)
  const { theMember } = deskData
  const today = new Date(theDay)
  const yesterday = new Date(today)
  const imMember = role === 'team_member' || role === 'team_admin'
  const imOfficeManager = role === 'office_manager'
  yesterday.setDate(yesterday.getDate() - 1)
  const { t } = useTranslation()

  const [theBooking, setBookingData] = useState({
    day: theDay === undefined ? new Date() : today,
    startTime: new Date().setHours(9, 0, 0),
    endTime: new Date().setHours(18, 0, 0),
    alreadyPaid: false,
    num_bookings: 1,
    price: 0,
    office,
    client: null,
    team: activeTeam === '' ? null : activeTeam,
    work_area: null,
    bookings: [
      {
        member: null,
        guest: null,
        booking_reference: null,
        pre_paid: null,
        complimentary: false,
        price: '',
        fee: '',
      },
    ],
  })

  const [theFilter, setFilter] = useState({
    status: 'active',
    name: '',
    member: '',
    type: '',
    team: '',
    order: '-start',
  })
  //const [pagination] = useState({
  //  resultsTotal: ' ... ',
  //  resultsByPage: 15,
  //  thePage: 1,
  //  pagesTotal: '',
  //})

  const [emptySearch] = useState([{ name: 'Loading...' }])
  const [teamMembers, setTeamMembers] = useState()
  const [numVisible, setNumVisible] = useState(false)
  const [bookAll, setBookAll] = useState(false)
  const [waData, setWAData] = useState({ work_areas: [{ name: 'Loading...', resources: [] }] })
  const [teamPrices, setTeamPrices] = useState()
  const [lists, setLists] = useState(false)
  const [theClients, setClients] = useState()
  const [theTeams, setTeams] = useState()
  const [errors, setErrors] = useState({
    team: null,
    work_area: null,
    booking_size: null,
    day: null,
    start: null,
    end: null,
  })

  const [feedback, setFeedBack] = useState({
    show: false,
    data: null,
  })

  const [searchClient, setSearchClient] = useState('')
  const [searchTeam, setSearchTeam] = useState('')

  //
  // Filters
  //
  function setClientFilter(value) {
    console.log(value)
    let queryClient = ''
    theClients.clients.map(c => {
      if (value !== undefined && value !== '' && value !== null) {
        queryClient = c.slug
      }
      return ''
    })
    if (queryClient !== undefined || queryClient !== '') {
      setFilter({ ...theFilter, client: queryClient })
      //const query = {
      //  pageSize: pagination.resultsByPage,
      //  currentPage: pagination.thePage,
      //  filter: { ...theFilter, client: queryClient },
      //}
    } else {
      setFilter({ ...theFilter, client: activeClient.slug })
      //const query = {
      //  pageSize: pagination.resultsByPage,
      //  currentPage: pagination.thePage,
      //  filter: { ...theFilter, client: '' },
      //}
    }
  }

  function setTeamFilter(value) {
    console.log(value)
    let queryTeam = ''
    theTeams.teams.map(c => {
      if (value !== undefined && value !== '' && value !== null) {
        queryTeam = c.slug
      }
      return null
    })
    if (queryTeam !== undefined || queryTeam !== '') {
      setFilter({ ...theFilter, team: queryTeam })
    } else {
      setFilter({ ...theFilter, team: '' })
      //const query = {
      //  pageSize: pagination.resultsByPage,
      //  currentPage: pagination.thePage,
      //  filter: { ...theFilter, team: '' },
      //}
    }
  }

  function setDataBookingsRows(i, value, type) {
    console.log(i, value, type)
    const rowsArr = theBooking.bookings
    switch (type) {
      case 'complimentary':
        rowsArr[i].complimentary = value === 'true'
        rowsArr[i].pre_paid = null
        rowsArr[i].price = 0
        break
      case 'alreadyPaid':
        rowsArr[i].alreadyPaid = value === 'true'
        rowsArr[i].price = 0
        break
      case 'member':
        rowsArr[i].member = value
        break
      case 'guest':
        rowsArr[i].guest = value
        break
      case 'booking_reference':
        rowsArr[i].booking_reference = value
        break
      case 'pre_paid':
        rowsArr[i].pre_paid = value
        rowsArr[i].price = 0
        break
      case 'single_price':
        rowsArr[i].price = value
        break
      case 'fee':
        rowsArr[i].fee = value
        break
      case 'credits':
        rowsArr[i].credits = value
        break
      default:
        return ''
    }
    console.log('rowsarr', rowsArr)
    return setBookingData({ ...theBooking, bookings: rowsArr })
  }

  function getMembersData(teamSlug) {
    if (teamSlug !== undefined) {
      getMembersByTeam(teamSlug)
        .then(r => {
          setTeamMembers(r)
          if (theMember !== undefined && theBooking.bookings[0].member === null) {
            const member = r.filter(m => m.slug === theMember.slug)
            console.log(member)
            setDataBookingsRows(0, member[0], 'member')
          }
        })
        .catch(e => console.log(e))
    }
  }

  function getClientsData(data) {
    const query = {
      currentPage: 1,
      pageSize: 25,
      filter: {
        layoutOffice: office.slug,
        office: office.slug,
        name: data !== undefined ? data : '',
      },
    }
    getClientsReferral(query).then(r => {
      //const client = r.clients.filter(t => t.slug === theClient?.slug)
      //const firstClient = client[0]
      //getMembersData(firstClient?.slug)
      //setBookingData({ ...theBooking, client: firstClient })
      setClients(r)
    })
  }

  function getTeamsData(data) {
    const query = {
      currentPage: 1,
      pageSize: 25,
      filter: {
        layoutOffice: office.slug,
        office: office.slug,
        name: data !== undefined ? data : '',
      },
    }
    newGetTeams(query).then(r => {
      //getMembersData(firstTeam?.slug)
      setTeams(r)
    })
  }

  function cleanErrors() {
    console.log(errors)
    if (
      errors.team !== null ||
      errors.client !== null ||
      errors.work_area !== null ||
      errors.day !== null ||
      errors.end !== null
    ) {
      console.log('Set errors to null')
      setErrors({
        team: null,
        client: null,
        work_area: null,
        day: null,
        end: null,
      })
    }
  }

  function calculatePrices() {
    cleanErrors()
    console.log('_onCalculatePrices ', theBooking, errors, bookAll)
    if (theBooking.work_area?.resources?.length === 0 && theBooking.work_area?.area_type !== 'suite') {
      setErrors({ ...errors, day: 'Work area busy' })
      return
    }
    //if (lists === true) {
    let resourceType = 'hot_desk'
    if (theBooking?.work_area?.area_type === 'suite' || bookAll) resourceType = 'suite'
    const query = {
      team: theBooking.team?.slug,
      client: theBooking.client === null ? activeClient.slug : theBooking.client?.slug,
      office: office.slug,
      workArea: theBooking?.work_area?.slug,
      bookingType: resourceType,
      bookingSize: theBooking?.bookings?.length,
      bookArea: bookAll,
      date: formatDates(theBooking.day)?.split('T')[0],
      fullArea: theBooking?.work_area?.area_type === 'suite' || bookAll,
    }
    calculateBookingPrice(query).then(r => {
      if (r === 'work_area_busy') {
        setErrors({ ...errors, day: 'Work area busy' })
      } else if (r?.data !== '') {
        const bookingsArr = theBooking.bookings
        bookingsArr.map((b, i) => {
          console.log(r?.pending_payment[i])
          if (r.broker_pending_payment.length >= i && r.broker_pending_payment.length > 0) {
            console.log('Setting broker price ', r?.broker_pending_payment[i]?.price, r?.broker_pending_payment[i]?.fee)
            bookingsArr[i].price = r?.broker_pending_payment[i]?.price
            bookingsArr[i].fee = r?.broker_pending_payment[i]?.fee
          } else {
            console.log('Setting normal price ', r?.pending_payment[i])
            bookingsArr[i].price = r?.pending_payment[i]
          }
          console.log(bookingsArr[i]?.price)
          return ''
        })
        setBookingData({ ...theBooking, bookings: bookingsArr })
        setTeamPrices(r)
      }
    })
    //}
  }
  //
  // Use effect
  //

  useEffect(() => {
    if (activeTeam) getMembersData(activeTeam.slug)
  }, [activeTeam])

  useEffect(() => {
    getClientsData()
  }, [])

  useEffect(() => {
    cleanErrors()
    const delayDebounceFn = setTimeout(() => {
      if (searchClient !== undefined && searchClient !== '') {
        //if (searchClient.filter) setClientFilter(searchClient.filter)
        if (searchClient.client) getClientsData(searchClient.client)
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchClient])

  useEffect(() => {
    cleanErrors()
    const delayDebounceFn = setTimeout(() => {
      if (searchTeam !== undefined && searchTeam !== '') {
        //if (searchTeam.filter) setTeamFilter(searchTeam.filter)
        if (searchTeam.team) getTeamsData(searchTeam.team)
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchTeam])

  function setNumRows(value) {
    if (value > 0) {
      const rowsArr = theBooking.bookings
      const deskBook = {
        member: null,
        guest: null,
        pre_paid: null,
        complimentary: false,
        price: 0,
      }
      const rowsDiff = value - rowsArr.length
      if (rowsDiff > 0) {
        for (let i = 0; i < rowsDiff; i += 1) {
          rowsArr.push(deskBook)
        }
        setBookingData({ ...theBooking, bookings: rowsArr })
      }
      if (rowsDiff < 0) {
        for (let i = 0; i < Math.abs(rowsDiff); i += 1) {
          rowsArr.pop()
        }
        setBookingData({ ...theBooking, bookings: rowsArr })
      }
    }
  }

  function getWAData() {
    setWAData({ work_areas: [{ name: 'Loading...', resources: [] }] })
    const aDay = formatDates(theBooking.day)?.split('T')
    const theStart = formatDates(new Date(theBooking.startTime), 'time')?.split('T')
    const query = {
      currentPage: 1,
      pageSize: 25,
      filter: {
        office: office.slug,
        area_type: 'open_space,suite',
        show_occupancy: 'hot_desk',
        status: 'active',
        availability_from: `${aDay[0]}T${theStart[1]}`,
      },
    }
    getDashboardWorkAreas(query).then(r => {
      const was = r.work_areas.filter(wa => wa.office.slug === office.slug)
      console.log(r.work_areas, was)
      if (theBooking.work_area === null) {
        const firstWA = was[0]
        setBookingData({ ...theBooking, work_area: firstWA })
      } else {
        was.map(wa => {
          console.log(wa.slug, theBooking.work_area.slug)
          if (wa.slug === theBooking.work_area.slug) {
            setBookingData({ ...theBooking, work_area: wa })
          }
          return wa
        })
      }
      setWAData(r)
      console.log(r)
    })
  }

  function validate(data) {
    let canCreate = false
    const textErrors = {
      team: 'Team or broker is required',
      client: 'Broker or team is required',
      work_area: 'Work Area is required',
      day: 'Day is required',
      start: 'Start time is required',
      end: 'End time is required',
      badTime: 'End time can be before start time',
      badDate: 'Booking day can`t be in the past',
    }

    let teamRes = data.team === null || data.team === undefined ? textErrors.team : null
    let clientRes = data.client === null || data.client === undefined ? textErrors.client : null
    const waRes = data.work_area === null || data.work_area === undefined ? textErrors.work_area : null
    const dayRes = data.day === null || data.day === undefined ? textErrors.day : null
    //if (data.day < yesterday) dayRes = textErrors.badDate
    const endRes = data.endTime <= data.startTime ? textErrors.badTime : null

    if (teamRes === null || clientRes === null) {
      teamRes = null
      clientRes = null
    }

    setErrors({
      team: teamRes,
      client: clientRes,
      work_area: waRes,
      day: dayRes,
      end: endRes,
    })
    if (clientRes === null && teamRes === null && waRes === null && dayRes === null && endRes === null) canCreate = true
    return canCreate
  }

  function createBookings(data) {
    if (!validate(data)) return
    setFeedBack({ show: true, data: null })
    const aDay = formatDates(theBooking.day)?.split('T')
    const theStart = formatDates(new Date(theBooking.startTime), 'time')?.split('T')
    const theEnd = formatDates(new Date(theBooking.endTime), 'time')?.split('T')
    const bookingsArr = []
    let resourceType = 'hot_desk'
    console.log('_onCreateBookings ', theBooking?.work_area, bookAll)
    if (theBooking?.work_area?.area_type === 'suite' || bookAll) resourceType = 'suite'
    theBooking.bookings.map(b => {
      const booking = {
        team: theBooking.team?.slug,
        client: theBooking.client === null ? activeClient.slug : theBooking.client?.slug,
        work_area: theBooking.work_area?.slug,
        price: b.price,
        fee: b.fee,
        start: { dateTime: `${aDay[0]}T${theStart[1]}` },
        end: { dateTime: `${aDay[0]}T${theEnd[1]}` },
        booking_type: resourceType,
        complimentary: b.complimentary,
        payNow: false,
        fullArea: theBooking?.work_area?.area_type === 'suite' || bookAll,
        alreadyPaid: b.alreadyPaid || false,
      }
      if (b.member !== null) booking.member = b.member.slug
      if (b.pre_paid !== null) booking.pre_paid = b.pre_paid.code
      if (b.guest !== null) booking.guest = b.guest.slug
      if (b.booking_reference !== null) booking.booking_reference = b.booking_reference
      console.log(booking)
      bookingsArr.push(booking)
      return ''
    })
    setBooking(bookingsArr)
      .then(r => {
        setFeedBack({ ...feedback, data: r })
        closeModal()
      })
      .catch(e => {
        console.log(e)
        setFeedBack({ show: false, data: 'Error' })
      })
  }

  useEffect(() => {
    console.log(theBooking.team, theBooking.client)
    if (theBooking.team !== null || theBooking.client !== null) calculatePrices()
  }, [theBooking.team, theBooking.client, theBooking.work_area, theBooking.bookings.length, bookAll, theBooking.day])

  useEffect(() => {
    if ((theBooking.client !== null || theBooking.team !== null || imMember) && theBooking.work_area !== null) {
      setLists(true)
    }
    if ((theBooking.client === null || theBooking.team === null || !imMember) && theBooking.work_area === null) setLists(false)
  }, [theBooking])

  useEffect(() => {
    getWAData()
  }, [theBooking.day])

  useEffect(() => {}, [errors])

  return (
    <ModalCard>
      <Modal open={feedback.show === true}>
        <FeedbackModal type='booking' data={feedback.data} />
      </Modal>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Grid container direction='row' justify='space-between' alignItems='center' style={{ marginBottom: '18px' }}>
            <Grid item>
              <ModalTitle>New booking</ModalTitle>
            </Grid>
            <Grid item>
              <Button onClick={() => closeModal()}>x</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <Autocomplete
                  label='Work Area'
                  name='work_area'
                  value={theBooking.work_area !== null ? theBooking.work_area : { name: 'Loading...', resources: [] }}
                  options={waData !== null ? waData?.work_areas : emptySearch}
                  getOptionLabel={option =>
                    option !== null && option !== undefined ? `${option.name} (${option?.resources?.length})` : ''
                  }
                  renderInput={params => (
                    <TextField {...params} label='Work Area' variant='outlined' error={errors.work_area !== null} />
                  )}
                  disabled={waData?.work_areas[0]?.name === 'Loading...'}
                  variant='outlined'
                  onChange={(e, data) => {
                    console.log('_onChange ', e.type, data)
                    cleanErrors()
                    if (data?.area_type === 'suite') {
                      setNumVisible(false)
                    } else if (data?.resources?.length === 0) {
                      setNumVisible(false)
                    } else if (
                      (theBooking.team !== null || theBooking.client !== null) &&
                      (!imMember || role === 'team_admin')
                    ) setNumVisible(true)
                    if (data !== null) setBookingData({ ...theBooking, work_area: data })
                    else setBookingData({ ...theBooking, work_area: null })
                  }}
                />
              </FormControl>
              {errors.work_area !== null ? <SmallError>{errors.work_area}</SmallError> : ''}
            </Grid>
            {role !== 'referral' && !imMember && (
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    options={theClients ? theClients?.clients : emptySearch}
                    value={theBooking.client}
                    getOptionLabel={option => option.name}
                    renderInput={params => <TextField {...params} label='Broker' variant='outlined' />}
                    onInputChange={(e, value) => {
                      console.log('_onInputChange ', e, value)
                      cleanErrors()
                      setSearchClient({
                        client: value,
                        filter: e?.target?.innerText,
                      })
                    }}
                    onChange={(e, data) => {
                      console.log('_onChange ', e, data)
                      if (data !== undefined && data !== null) {
                        setClientFilter(data.slug)
                        setBookingData({ ...theBooking, client: data })
                        if (theBooking.work_area !== null && theBooking.work_area.area_type !== 'suite') setNumVisible(true)
                      } else {
                        setBookingData({ ...theBooking, client: null })
                        if (theBooking.team === null) setNumVisible(false)
                      }
                    }}
                    loading
                    loadingText='Searching...'
                    noOptionsText='No Results'
                    error={errors.client !== null}
                  />
                  {errors.client !== null ? <SmallError>{errors.client}</SmallError> : ''}
                </FormControl>
              </Grid>
            )}
            {!imMember && (
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    options={theTeams ? theTeams?.teams : emptySearch}
                    getOptionLabel={option => option.name}
                    value={theBooking.team}
                    renderInput={params => <TextField {...params} label='Teams' variant='outlined' />}
                    onInputChange={(e, value) => {
                      if (e.type === 'change') {
                        cleanErrors()
                        setSearchTeam({
                          team: value,
                          filter: e?.target?.innerText,
                        })
                      }
                    }}
                    onChange={(e, data) => {
                      if (data !== undefined && data !== null) {
                        setTeamFilter(data.slug)
                        setBookingData({ ...theBooking, team: data })
                        getMembersData(data.slug)
                        if (theBooking.work_area !== null && theBooking.work_area.area_type !== 'suite') setNumVisible(true)
                      } else {
                        setBookingData({ ...theBooking, team: null })
                        setTeams({ teams: [] })
                        if (theBooking.client === null) setNumVisible(false)
                      }
                    }}
                    loading
                    loadingText='Searching...'
                    noOptionsText='No Results'
                    error={errors.team !== null}
                  />
                  {errors.team !== null ? <SmallError>{errors.team}</SmallError> : ''}
                </FormControl>
              </Grid>
            )}
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <DatePicker
                  value={theBooking?.day ? theBooking.day : new Date()}
                  name='day'
                  inputVariant='outlined'
                  helperText={null}
                  format='dd-MM-yyyy'
                  onChange={value => setBookingData({ ...theBooking, day: value })}
                  label='Day'
                  autoOk
                  error={errors.day !== null}
                />
                {errors.day !== null ? <SmallError>{errors.day}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TimePicker
                  label='Start'
                  inputVariant='outlined'
                  name='startTime'
                  minutesStep={5}
                  value={theBooking?.startTime}
                  renderInput={params => <TextField {...params} variant='outlined' />}
                  onChange={value => setBookingData({ ...theBooking, startTime: value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TimePicker
                  label='End'
                  name='endTime'
                  inputVariant='outlined'
                  minutesStep={5}
                  value={theBooking?.endTime}
                  renderInput={params => <TextField {...params} variant='outlined' />}
                  onChange={value => setBookingData({ ...theBooking, endTime: value })}
                  error={errors.end !== null}
                />
              </FormControl>
              {errors.end !== null ? <SmallError>{errors.end}</SmallError> : ''}
            </Grid>
            {numVisible && role !== 'team_member' && (
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    type='number'
                    value={!bookAll ? theBooking.bookings.length : theBooking.work_area?.resources?.length}
                    variant='outlined'
                    name='num_bookings'
                    label={t('booking.form.numberDesks')}
                    onChange={e => {
                      setBookingData({
                        ...theBooking,
                        num_bookings: parseInt(e.target.value, 10) >= 1 ? parseInt(e.target.value, 10) : 1,
                      })
                      setNumRows(e.target.value)
                    }}
                    onWheel={e => e.preventDefault()}
                    InputProps={{ inputProps: { min: 0, max: theBooking.work_area?.resources?.length } }}
                    disabled={bookAll}
                  />
                </FormControl>
              </Grid>
            )}
            {numVisible && theBooking?.work_area?.bookable === true && (
              <Grid item sm={2}>
                <Grid container direction='column' alignItems='center' justify='center'>
                  <Grid item>
                    <FormControl>
                      <Checkbox
                        size='small'
                        value={bookAll}
                        onChange={() => {
                          setBookAll(!bookAll)
                          setBookingData({
                            ...theBooking,
                            num_bookings: 1,
                          })
                          setNumRows(1)
                        }}
                        name='book_all'
                      />
                    </FormControl>
                  </Grid>
                  <Grid item style={{ fontSize: '10px' }}>
                    Book all
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          {lists === false ? (
            ''
          ) : (
            <Grid item style={{ marginBottom: '15px' }}>
              {t('booking.list')}
            </Grid>
          )}
          {lists === false
            ? ''
            : theBooking.bookings?.map((b, i) => {
                console.log(theBooking.team)
                return (
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='space-between'
                    key={i}
                    spacing={1}
                    style={{ borderTop: `1px solid ${colors.softgray}`, marginBottom: '8px' }}
                  >
                    {role !== 'referral' &&
                      ((theBooking.client === null && theBooking.team !== null) || imMember) &&
                      numVisible &&
                      !bookAll && (
                        <Grid item sm={3}>
                          <FormControl style={{ width: '100%' }}>
                            <Autocomplete
                              label='Member'
                              name='member'
                              value={b?.member}
                              options={
                                teamMembers !== undefined
                                  ? teamMembers
                                  : [{ user: { first_name: 'No', last_name: 'Data' } }]
                              }
                              getOptionLabel={option => `${option.user.first_name} ${option.user.last_name}`}
                              renderInput={params => <TextField {...params} label='Member' variant='outlined' />}
                              variant='outlined'
                              onChange={(e, data) => {
                                if (data !== undefined) setDataBookingsRows(i, data, 'member')
                              }}
                            />
                          </FormControl>
                        </Grid>
                      )}
                    {console.log(role, theBooking.client, theBooking.team, imMember)}
                    {role !== 'referral' &&
                      ((theBooking.client === null && theBooking.team !== null) || imMember || imOfficeManager) &&
                      numVisible &&
                      !bookAll && (
                        <Grid item sm={3}>
                          <FormControl style={{ width: '100%' }}>
                            <Autocomplete
                              label='Package'
                              name='pre_paid'
                              options={teamPrices?.selected_prepaids}
                              getOptionLabel={option =>
                                `${option?.membership?.plan_membership?.name} - £${option?.single_price}`
                              }
                              renderInput={params => <TextField {...params} label='Package' variant='outlined' />}
                              variant='outlined'
                              disabled={b.complimentary === true}
                              onChange={(e, data) => {
                                if (data !== undefined) setDataBookingsRows(i, data, 'pre_paid')
                              }}
                            />
                          </FormControl>
                        </Grid>
                      )}
                    {console.log('About reference ', theBooking.client, theBooking.team, !imMember)}
                    {(role === 'referral' || (theBooking.client !== null && theBooking.team === null)) && !imMember && (
                      <Grid item sm={3}>
                        <FormControl>
                          <TextField
                            value={b?.booking_reference}
                            onChange={e => setDataBookingsRows(i, e.target.value, 'booking_reference')}
                            name='booking-reference'
                            label={t('booking.form.reference')}
                            variant='outlined'
                          />
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item sm={2}>
                      <FormControl>
                        <TextField
                          type='number'
                          value={b.price}
                          onChange={e =>
                            e.target.value >= 0 ? setDataBookingsRows(i, e.target.value, 'single_price') : 0
                          }
                          name='single-price'
                          label='Price (VAT in)'
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          disabled={role === 'referral' || imMember}
                        />
                      </FormControl>
                    </Grid>
                    {!numVisible && !imMember && (
                      <Grid item sm={2}>
                        <Grid container direction='column' alignItems='center' justify='center'>
                          <Grid item>
                            <FormControl>
                              <Checkbox
                                size='small'
                                checked={b.credits === true}
                                onChange={e => {
                                  setDataBookingsRows(i, e.target.checked, e.target.name)
                                }}
                                name='credits'
                              />
                            </FormControl>
                          </Grid>
                          <Grid item style={{ fontSize: '10px' }}>
                            Use Credits
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {role === 'referral' && !imMember && (
                      <Grid item sm={2}>
                        <FormControl>
                          <TextField
                            id={`broker-fee${i}`}
                            type='number'
                            value={b.fee}
                            onChange={e => (e.target.value >= 0 ? setDataBookingsRows(i, e.target.value, 'fee') : 0)}
                            name={`broker-fee${i}`}
                            label='Fee'
                            variant='outlined'
                            disabled
                          />
                        </FormControl>
                      </Grid>
                    )}
                    {role !== 'referral' && !imMember && (
                      <Grid item sm={2}>
                        <Grid container direction='column' alignItems='center' justify='center'>
                          <Grid item>
                            <FormControl>
                              <Checkbox
                                size='small'
                                checked={b.complimentary === true}
                                onChange={e => {
                                  setDataBookingsRows(i, b.complimentary === true ? 'false' : 'true', e.target.name)
                                }}
                                name='complimentary'
                              />
                            </FormControl>
                          </Grid>
                          <Grid item style={{ fontSize: '10px' }}>
                            Complimentary
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {role !== 'referral' && !imMember && (
                      <Grid item sm={2}>
                        <Grid container direction='column' alignItems='center' justify='center'>
                          <Grid item>
                            <FormControl>
                              <Checkbox
                                size='small'
                                checked={b.alreadyPaid === true}
                                onChange={e => {
                                  setDataBookingsRows(i, b.alreadyPaid === true ? 'false' : 'true', e.target.name)
                                }}
                                name='alreadyPaid'
                              />
                            </FormControl>
                          </Grid>
                          <Grid item style={{ fontSize: '10px' }}>
                            Already Paid
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )
              })}
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='center'>
            <Grid item>
              <Button color='secondary' variant='contained' onClick={() => createBookings(theBooking)}>
                Create Booking
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalCard>
  )
}

ModalCreateDesk.propTypes = {
  closeModal: PropTypes.func,
  theDay: PropTypes.object,
  office: PropTypes.object,
  deskData: PropTypes.object,
  activeClient: PropTypes.object,
  role: PropTypes.string,
  activeTeam: PropTypes.object,
}

ModalCreateDesk.defaultProps = {
  closeModal: () => {},
  office: {},
  theDay: {},
  deskData: { status: 'none' },
  activeClient: {},
  role: '',
  activeTeam: {},
}

export default ModalCreateDesk
